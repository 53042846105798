.player {
    display: flex;
    cursor: pointer;
}

@keyframes play{
    0%{
        transform:scaleY(1)
    }
    50%{
        transform:scaleY(2)
    }
    100%{
        transform:scaleY(1)
    }

}

.playing{
    background-color: transparent;
    border: 1px solid;
    height: 1rem;
    width: 2px;
    margin: .5rem 0.1rem;
    animation: play 1s ease-in-out infinite;
}
.playing.paused{
    animation-play-state: paused;
}
span:nth-child(1){
    animation-delay: 0.2s;
}
span:nth-child(2){
    animation-delay: 0.3s;
}
span:nth-child(3){
    animation-delay: 0.4s;
}
span:nth-child(4){
    animation-delay: 0.5s;
}
span:nth-child(5){
    animation-delay: 0.8s;
}
