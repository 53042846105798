@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&family=Nanum+Gothic+Coding&display=swap');

* {
  font-family: 'Major Mono Display', monospace;
  padding: 0;
  margin: 0;
}

body {
  cursor: url('./cursors/mouse-cursor.png'), auto;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    to right,
    #f582ae 0%,
    #f582ae 40%,
    #fef6e4 40%,
    #fef6e4 100%
  );

  font-size: 1.15em;
  align-items: center;
  justify-content: center;
};

object {
  height: 800px;
  width: 900px;
}

.headshot {
  border-radius: 50%;
  width: auto;
  max-width: 400px;
  height: auto;
  max-height: 400px;
  border: solid;
  border-color: #a786df;
  border-width: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.socials{
  text-align: center;
  margin: 1em;
}
.fullcontainer {
  max-width:auto;
  max-width:60rem;
  padding: 0 1em;
}

.homepage-container {
  min-height: 300px;
  max-width: 100%;
  display: flex;
  margin: 3em 8em;
  align-items: center;
  justify-content: center;
}
.homepage {
  cursor: url('./cursors/mouse-cursor.png'), auto;
  max-height: 600px;
  min-width: 300px;
  max-height: fit-content;
  font-size: 1.15em;
  align-items: center;
  justify-content: center;
  padding: 8em;
}
.content-container{
  min-height: 300px;
  max-width: 100%;
  display: flex;
  margin: 2em;
  align-items: center;
  justify-content: center;
}

.about {
  cursor: url('./cursors/mouse-cursor.png'), auto;
  max-height: 200px;
  width: 100%;
  max-height: fit-content;
  background: #f9f8fc;
  font-size: 1.15em;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 8px;


}

.contact {
  cursor: url('./cursors/mouse-cursor.png'), auto;
  min-height: 600px;
  max-height: fit-content;
  background: #f9f8fc;
  font-size: 1.15em;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 8px;
}


p {
  color:#172c66;
  padding: .2em;
  font-family: 'Nanum Gothic Coding', monospace;
  font-size:1em;
  text-align: left;
}

h1 {
  padding-bottom: 0.2em;
  font-size: clamp(2rem, 1rem + 10vw, 4rem);
  color:#001858;
}

h4 {
    font-size: 8vh,
}

a {
  color: #0e172c;
  text-decoration: none;
}

a:hover{
  background-color: #078080;
  color:#fef6e4;
  cursor: url('./cursors/cursor.png'), auto;
  border-radius: 8px;
}

.videoLink {
  text-decoration: none;
  color:#078080;
}

.videoLink:hover{
  background-color: #078080;
  color:#fef6e4;
  cursor: url('./cursors/cursor.png'), auto;
  border-radius: 8px;
  padding: .3em
}

.projectLink{
  text-decoration: none;
}
.projectLink:hover {
  background-color: #078080;
  color:#fef6e4;
  cursor: url('./cursors/cursor.png'), auto;
  border-radius: 8px;
}

.hb:hover {
  background-color: #078080;
  color:#fef6e4;
  cursor: url('./cursors/cursor.png'), auto;
  border-radius: 8px;
  padding: .3em
}
.projectButton{
  text-decoration: none;
  color:#078080;
}

a.projectButton:hover{
  padding: .3em
}
.projectButton:hover{
  background-color: #078080;
  color:#fef6e4;
  cursor: url('./cursors/cursor.png'), auto;
  border-radius: 8px;
}
.hb{
  text-decoration: none;
  color:#078080;
}
i:hover{
  opacity: 0.6;
  color: #078080;
  cursor: url('./cursors/cursor.png'), auto;

}

button{
  font-family: 'Nanum Gothic Coding', monospace;
  border-radius: 8px;
  border: #f582ae;
  background-color: transparent;
  margin-bottom: 300px;
}

button:hover{
  background-color: #078080;
  color:#fef6e4;
  cursor: url('./cursors/cursor.png'), auto;
}

.reachout{
  text-align: center;
}

.contact-me{
  text-align: left;
  padding: 0 2rem 0 2em;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 400px;
  line-height: 1.5rem;
}

input{
  border: none;
  border-bottom: 1px solid #001858;
  background-color: transparent;
  width: 400px;
  margin-left: 3px;
  color:#001858;
  font-size: larger;
}

form {
  border-style: none;
  font-family: 'Nanum Gothic Coding', monospace;
  color:#001858;
  margin-top: none;
  width:auto;
}

label{
  text-align: left;
  font-size: 20px;
  line-height: 1;
  margin-bottom: none;
  padding-bottom: none;
  margin-top: 1em;
}

.submit-button {
  padding: .5em;
  width: 80px;
  font-family: 'Nanum Gothic Coding', monospace;
  border-radius: 10px;
  border: #f582ae;
  border-style: solid;
  background-color: transparent;
  margin-bottom: 5%;
  align-self: center;
}
.img {
  max-width: 100%;
}
.submit-button:hover{
  background-color: #f582ae;
  color: #f9f8fc;
  cursor: url('./cursors/cursor.png'), auto;
}

input:focus {
  outline:none !important;
}

input:hover {
  cursor: url('./cursors/quill-pen.png'), auto;
}
textarea{
  background-color:#f9f8fc;
}
textarea:hover {
  cursor: url('./cursors/quill-pen.png'), auto;
}
textarea:focus {
  outline:none !important;
}

label:hover{
  cursor: url('./cursors/quill-pen.png'), auto;
}


.techstack {
  width: 100%;
  height: auto;
  border-radius: 10px;
  filter: drop-shadow(8px 8px 4px #078080);
  margin-top: 3%;
  padding: none;
}

.title{
  background: linear-gradient(180deg, #730040 0%, #301cbe 100%);
  background-clip: text;
  color: transparent;
}

.titleSpan {
  background: linear-gradient(180deg, #ffd7ff 0%, #ffb6ff 100%);
  color: transparent;
}


.language{
  background: linear-gradient(180deg, #730040 0%, #301cbe 100%);
  background-clip: text;
  color: transparent;
  font-size: 300%;
  line-height: 1;
  text-align: center;
  animation: fadeInOut linear 5s infinite;
  margin-bottom: auto;
  margin-top: auto;
}

.fullstack {
  align-items: left;
  width: 100%;
  height: auto;
}

@keyframes fadeInOut {
  0% {
    opacity: 0%;
  }
  50% {
    opacity: 1;
  }
  100%{
    opacity: 0%;
  }
  
}

@keyframes textclip {
  to {

    background-position: 200% center
}

}
.cards {
  display: flex;
  justify-content: center;
  align-content: space-between;
  flex-wrap:wrap;
}
.card.project{
  background: #fff;
  width: 24em;
  border-radius: 0.6em;
  margin: 1em;
  overflow: hidden;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
}

.card.project h2 {
  padding: none;
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 1.3em;
  font-weight: 500;
  transition: all ease-in 100ms;
  cursor: none;
  max-width: fit-content;
}
.card.project h2:hover {
  transform: scale(1.1);
  cursor: url('./cursors/cursor.png'), auto;
  background-color: #078080;
  color:#fef6e4;
  border-radius: 8px;
}
.card.project p {
  color: #777;
  transition: all ease-in 100ms;
  cursor: none;
}


.card-body.project {
  padding: 1.2em;
  cursor: url('./cursors/mouse-cursor.png'), auto;
};

.card-body.project:hover{
  cursor:  url('./cursors/mouse-cursor.png'), auto;
};


.card.project h5 {
  color: #bbb;
  font-weight: 700;
  font-size: 0.7em;
  letter-spacing: 0.04em;
  margin: 1.4em 0 0 0;
  text-transform: uppercase;
  transition: all ease-in 100ms;
  cursor:  url('./cursors/mouse-cursor.png'), auto;

}

.card.project:hover {
    transform: scale(1.03);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
    color: #078080;
    cursor:  url('./cursors/mouse-cursor.png'), auto;
  }
  

  .card.project p:hover{
    cursor: url('./cursors/mouse-cursor.png'), auto ;
  }
  .card.project h5:hover { 
    cursor:  url('./cursors/mouse-cursor.png'), auto;
  }

  .projects {
    cursor: url('./cursors/mouse-cursor.png'), auto;
    min-height: 90vh;
    width: 100%;
    max-height: fit-content;
    background: #f9f8fc;
    font-size: 1.15em;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    border-radius: 8px;
    margin: 8vh;
    margin-top: 10vh;

  }


.footer {
  padding: .1em;
  background-color: transparent;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
 
}

.footeritem {
  height: 50px;
  width: 50px;
  padding-bottom: 0;
  margin: 0 1em;
  
}

.footeritem:hover{
  background-color: #F582AE;
  cursor: url('./cursors/cursor.png'), auto;
  border-radius: 8px;
}

@media all and (max-width: 768px){
  .footer {display: none;}
};

