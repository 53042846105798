.dollyButton {
    border-style: solid;
    border-radius: 8px;
    padding: .2em;
    max-width: 15em;
    border-color:#078080;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  
.dollyButton:hover {
    cursor: url('../../cursors/cursor.png'), auto;
}
.dollyQuotes {
    font-family: cursive;
    font-size: 5vh;
    text-align: center;
}

 /* Only show the "Thanks Dolly" -back to top- button when screen is smaller and needs to scroll */
@media all and (min-width: 1000px){
    .dollyButton.top {
        display: none;}
  };